@import "../../styles/variables";

.filters-container {
    &::-webkit-scrollbar {
        display: none;
    }

    height: calc(100vh - 80px);
    background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);
    overflow-y: hidden;

    .apply-btn-wrapper {
        padding: 0.5rem !important;

        .btn {
            width: 100% !important;
        }

        .title-cost {
            color: #fff;
        }

        .form-control {
            padding: 0.15rem 0.75rem !important;
        }
    }

    .stock-status {
        .form-check>label {
            color: #fff;
            font-size: 12px;
        }

        #default-credit-card {
            background-color: $primaryColor;
            border: 2px solid #fff !important;
            box-shadow: none !important;
        }

        #default-credit-card:checked {
            background-image: url(../../assets/icons/check.svg) !important;
        }
    }
}

.filters-accordion.accordion {

    .accordion-item {
        .accordion-body {
            &::-webkit-scrollbar {
                display: none;
            }

            .form-check>label {
                color: #fff;
                font-size: 12px;
            }

            #default-credit-card {
                background-color: $primaryColor;
                border: 2px solid #fff !important;
                box-shadow: none !important;
            }

            #default-credit-card:checked {
                background-image: url(../../assets/icons/check.svg) !important;
            }
        }

        border: none !important;
        background-color: transparent;

        .accordion-header {
            .accordion-button {
                // padding-bottom: 0rem !important;

                &:focus,
                &:not(.collapsed) {
                    box-shadow: none !important;
                }

                &::after {
                    background-image: url(../../assets/icons/expand.svg);

                }

                &:not(.collapsed)::after {
                    background-image: url(../../assets/icons/expand.svg);
                    transform: var(--bs-accordion-btn-icon-transform);
                }

                color: #fff;
                background-color: transparent
            }
        }
    }
}
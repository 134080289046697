@import "../../styles/variables";

.btn.btn-primary,
.btn.btn-outline-primary {
    // width: 100%;
    box-shadow: 0px 20px 24px rgba(39, 150, 60, 0.24);
    border-radius: 100px;
    outline: none;
    border: none;
    // padding: 0.5rem 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $whiteColor;
    white-space: nowrap;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn.btn-primary {
    background: $primaryColor !important;

    &:hover {
        background: $secondaryColor !important;
    }
}

.white.btn.btn-primary {
    background: $whiteColor !important;
    color: $primaryColor !important;

    &:hover {
        background: #eee !important;
    }
}

.btn.btn-outline-primary {
    outline: $primaryColor !important;
    border: 1px solid $primaryColor !important;
    color: $primaryColor !important;

    &:hover {
        background: $primaryColor !important;
        color: $whiteColor !important;
    }
}
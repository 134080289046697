@import "../../styles/variables";

.form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    align-items: center;
    color: $textColor;
}

.form-control::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.54);
}

.form-check-input:checked {
    background-color: #27963C;
    border-color: #27963C;
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        .is-invalid {
            border: 1px solid red !important;
            border-radius: 6px !important;
            //   border-color: var(--bs-form-invalid-border-color);
            padding-right: calc(1.5em + 0.75rem);
            background-image: url(../../assets/icons/error_icon.svg);
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }

        input {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            background-clip: padding-box;
            border: var(--bs-border-width) solid var(--bs-border-color);
            appearance: none;
            border-radius: var(--bs-border-radius);
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }
}

.form-control:disabled {
    background-color: $whiteColor !important;
    opacity: 1;
    border-color: hsl(0, 0%, 90%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: hsl(0, 0%, 60%);
}

.price-list-input {
    .invalid-feedback {
        font-size: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@import "../../styles/variables";

.credit-card {
    position: relative;
    border-radius: 10px;
    background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);

    .title,
    .value {
        color: $whiteColor !important;
    }

    .btn.btn-primary {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        padding: 0.5rem !important;
        width: initial;
    }

    #default-credit-card {
        background-color: $primaryColor;
        border: 2px solid #fff !important;
        box-shadow: none !important;
    }

    #default-credit-card:checked {
        background-image: url(../../assets/icons/check.svg) !important;
    }
}


@import "../../../styles/variables";

.card-row {
    margin-top: 10px;
    margin-bottom: 30px;
}

.orders-card {
    background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);
    height: 6rem;
    overflow: hidden;

    .card-body {
        overflow: hidden;

        .card-title {
            color: $whiteColor;
        }
    }

    .card-img-top {
        border-radius: 20px 0px 0px 20px;
        width: 160px;
        object-fit: cover;
        margin-bottom: auto;
    }

}


.orders-tabs {
    .ag-header-cell:last-child {
        .ag-header-cell-label {
            justify-content: end;
        }
    }

    .nav-tabs {
        gap: 0.15rem;
        border-bottom: 2px solid $primaryColor;

        .nav-item {
            margin-bottom: 0.05rem;

            .nav-link {
                border-color: $whiteColor;
                background-color: #eee;
                color: #000;
                text-transform: capitalize;
            }

            .nav-link.active {
                background-color: $whiteColor;
                color: $primaryColor;
                text-transform: capitalize;
            }
        }
    }
}

.Unpaid,
.Open,
.Shipped,
.fulfilled {
    font-weight: 600;
    line-height: 18px;
}

.Shipped, .fulfilled {
    color: $primaryColor;

}

.Unpaid {
    color: #962727;
}

.Open {
    color: #E0D700;
}

[col-id="action"] {
  display: flex;
  align-items: center;
}
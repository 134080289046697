.search-container {
    .form-control {
        padding-left: 2.25rem;
        border-radius: 2rem;
    }
    .search-icon {
        position: absolute;
        left: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
    }
    
}
@import "../../styles/variables";

.product-details-container {

    .page-title,
    .brand,
    .selling-price>span {
        color: $primaryColor;
    }

    .quantity {
        background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);
        color: $whiteColor;
        border-radius: 5px;
        padding: 6px, 8px, 6px, 8px;

    }

    .content {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5rem;
        color: #666666;
    }

    .variants-wrapper {
        .chip {
            width: initial;
        }
    }

    .disabled-img-icon {
        opacity: 0.5;
    }
}

.popup-title {
    color: $primaryColor;
}

.form-check-input {
    border: 2px solid #27963C !important;
}

@media only screen and (min-width: $screen-md) {
    .product-details-container {
        .right-section {
            height: calc(80vh);
            padding-bottom: 20px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

}

@media only screen and (min-width: $screen-lg) {
    .product-details-container {
        height: calc(100vh - 80px);
        overflow: hidden;
    }
}

@media only screen and (max-width: $screen-sm) {
    .product-details-container {
        overflow: hidden;
    }
}
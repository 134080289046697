// Screen sizes
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$textColor: #27963C;
$primaryColor: #27963C;
$secondaryColor: #1D6434;
$whiteColor: #fff;

$secondaryLight1Color: #FBF7ED;
$secondaryLight2Color: #FFF8F6;

$warningColor: #C43256;
$warningLight1Color: #c432571f;
@import "../../styles/variables";

.navbar {
    padding: 0.125rem;
    background: $whiteColor;
    border-bottom: 2px solid $primaryColor;

    .nav-text {
        font-style: normal;
        font-weight: 600;
        // font-size: 18px;
        line-height: 120%;
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        white-space: nowrap;
    }

    .navbar-nav {
        .nav-link {
            color: $secondaryColor;

        }

        .nav-link.active {
            color: $primaryColor;
        }
    }

    .user-profile {
        img {
            width: 3rem;
            height: 3rem;
        }

        div {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: $primaryColor;
            }

            p.user-name {
                font-weight: 500;
            }
        }
    }
}

#collasible-nav-dropdown {
    .user-profile {
        img {
            height: 1.5rem;
            margin-left: 1.5rem;
        }
    }

}

.dropdown-toggle::after {
    display: none !important;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media screen and (max-width: $screen-sm) {

    .offcanvas.offcanvas-start.show,
    .offcanvas.offcanvas-end.show {
        width: 80vw;
    }

    .offcanvas.offcanvas-end.show {
        background: linear-gradient(154.64deg, #27963C 1.94%, #1D6434 84.73%);

        .apply-btn-wrapper {
            padding: 0.5rem !important;

            .title-cost {
                color: #fff;
            }
        }
    }
}


@media screen and (min-width: $screen-lg) {
    .navbar {
        padding: 0.125rem 3rem;
    }

    .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: -5rem !important;
        margin-top: 0.125rem !important;
    }
}

// @media screen and (max-width: $screen-sm) {
//     .navbar {
//         .container-fluid {
//             flex-direction: row-reverse;
//         }
//     }
// }
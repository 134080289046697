.is-invalid {
   .eye-icon {
      width: 1.5rem;
      height: 1.75rem;
      position: absolute;
      top: 2.15rem;
      right: 1rem;
   }
   .eye-icon.eye-closed {
      opacity: 0.7;
   }
}

.is-invalid.form-control+.eye-icon {
   width: 1.5rem;
   height: 1.75rem;
   position: absolute;
   top: 2.15rem;
   right: 2rem;
}
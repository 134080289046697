@import "../../styles/variables";

.chip {
    position: relative;
    width: 110%;
    text-align: center;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 1px solid $primaryColor;
    font-size: 10px;
    color: $primaryColor;
    cursor: pointer;
}

.chip.oval {
    border-radius: 50px;

}

.chip.active {
    background: #27963C;
    color: #fff;
}

.chip.out-of-stock {
    border: 1px solid #666666;
    color: #666666;
}

.chip.out-of-stock.active {
    border: 1px solid #666666;
    color: #fff;
}

.chip.out-of-stock::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #666666;
    position: absolute;
    left: 0;
    bottom: 10px;
}

.chip.out-of-stock.active::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 10px;
} 
.user-profile-card {
    .btn {
        width: 100% !important;
    }
    .card-body {
        padding: 0.75rem 1rem !important;

        .user-name {
            font-weight: 600;
            font-size: 20px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #27963C;
        }

        .user-email {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #27963C;
        }

        .user-profile.h5 {
            display: none;
        }
    }
}
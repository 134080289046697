@import "../../../styles/variables";

.account-tabs {
    .nav-tabs {
        gap: 0.15rem;
        border-bottom: 2px solid $primaryColor;

        .nav-item {
            margin-bottom: 0.05rem;

            .nav-link {
                border-color: $whiteColor;
                background-color: #eee;
                color: #000;
                text-transform: capitalize;
            }

            .nav-link.active {
                background-color: $whiteColor;
                color: $primaryColor;
                text-transform: capitalize;
            }
        }
    }
}

.contact-info-card {
    .card-body>.row>div {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .card-body {

        .btn.btn-primary,
        .btn.btn-outline-primary {
            width: 100% !important;
            padding: 0.5rem !important;
        }
    }

}

.terms-card {
    background: linear-gradient(154.64deg, $textColor 1.94%, $secondaryColor 84.73%);

    .card-body {
        .title {
            color: $whiteColor;
        }
    }
}

.subscriptionDetails-card {
    .card-body {
        .title {
            color: $primaryColor;
        }

        .btn.btn-primary,
        .btn.btn-outline-primary {
            padding: 0.5rem !important;
        }
    }
}

.paymentDetails-card {
    .card-body {
        padding: 1rem !important;
        .card-lists {
            width: 100%;
            overflow-x: auto;
        }
        overflow: hidden;
        .add-credit-card-btn.btn.btn-primary,
        .btn.btn-outline-primary {
            width: initial;
            padding: 0.5rem 3rem !important;
        }
    }
}
.fileUploadContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);

  .fileUploadField {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }

  .logoContainer {
    pointer-events: none;
    display: flex;
    align-items: center;
    position: relative;

    img {
      height: 1.5rem;
    }

    div {
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
      }

      .dnd {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: var(--color-grey);
      }
    }
  }

  .d-block.invalid-feedback {
    margin-top: 5.5rem !important;
  }
}
@import "../../../styles/variables";

.product.card {
    box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.16);
    .card-body {
        overflow: hidden;

        .card-title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $primaryColor !important;
            height: 40px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #666666;
        }

        .body_html.card-text {
            height: 40px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .product-footer {
            .btn {
                width: initial;
            }

            .selling-price {
                font-weight: 600;
            }
        }

        .form-check-input {
            border: 2px solid #27963C !important;
        }
    }

    .stock-chip {
        position: absolute;
        right: 0;
        top: 1rem;
        border: 100px 0px 0px 100px;
        color: #fff;
        font-size: 12px;
        padding: 0.1rem 0.25rem;
        border: 100px 0px 100px 0px;
        border-radius: 100px 0px 0px 100px;
        z-index: 900;

        &.out-of-stock {
            background-color: #962727;

        }

        &.in-stock {
            background-color: #27963C;

        }


    }
}
.custom-select.is-invalid__control {
  border: 1px solid red !important;
  border-radius: 6px !important;
  //   border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url(../../assets/icons/error_icon.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.__control--is-disabled {
  background-color: #fff !important;
}